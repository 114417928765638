
import { defineComponent } from "vue";
import CardTitleJaya from "@/components/global/CardTitleJaya.vue";

export default defineComponent({
  name: "explore-results-menu",
  props: {
    diagnosticId: { type: Number, required: true },
    exploitaitonId: { type: Number, required: true },
  },
  components: {
    CardTitleJaya,
  },
});
