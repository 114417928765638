
import { correctResultColor, notify } from "@/core/helpers/globalJaya.ts";
import { computed, defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import CardTitleJaya from "@/components/global/CardTitleJaya.vue";
import FactorLegend from "@/components/diagnostic/FactorLegend.vue";
import ExploreResultsMenu from "@/components/diagnostic/ExploreResultsMenu.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { parseDate } from "@/core/helpers/globalJaya";
import { Diagnostic } from "@/store/modules/DiagnosticsModule";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "diagnostic-results",
  components: {
    CardTitleJaya,
    ExploreResultsMenu,
    FactorLegend,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    let diagnostic = ref<Diagnostic>({
      id: -1,
      date_evaluated: new Date().toISOString(),
      is_real: false,
      finished: false,
      exploitation: -1,
      exploitation_id: -1,
      date_created: new Date().toISOString(),
      results: [],
    });
    let factorLevels = ref<number[]>([]);
    let globalScore = ref<number>(0);
    let chartType = ref<string>("bar");
    let displayChart = ref<boolean>(true);
    let selectedGroup = ref<number | null>(null);

    const diagnosticId = computed(
      () => +router.currentRoute.value.params.diagnosticId
    );

    const factors = computed(() => store.getters.getFactors);

    const diagnosticName = computed(() => {
      if (diagnostic.value.id > 0) {
        const date_created = parseDate(diagnostic.value.date_created);
        if (
          store.getters.getExploitations.some(
            (exploitation) =>
              exploitation.id === diagnostic.value.exploitation_id
          )
        ) {
          let linkedExploitationName = store.getters.getExploitations.find(
            (exploitation) =>
              exploitation.id === diagnostic.value.exploitation_id
          ).name;
          return (
            "Diagnostic de '" +
            linkedExploitationName +
            ", créé le " +
            date_created +
            " (année culturale " +
            new Date(diagnostic.value.date_evaluated).getFullYear() +
            ")"
          );
        }
      }
      return "";
    });

    const ecosystemicServicesCategories = computed(() => {
      return store.getters.getEcosystemicServicesCategories.map((esc) => ({
        ...esc,
        score: Math.round(100 * computeCategoryScore(esc)),
      }));
    });

    const factorsGroups = computed(() => {
      return store.getters.getFactorGroups;
    });

    const setFactorLevels = (groupId: number | null = null) => {
      let factorLevelsResult = ref<number[]>([]);
      let factorsToCompute = factors.value;
      if (groupId) {
        factorsToCompute = factors.value.filter((e) => {
          return e.group === groupId;
        });
      }
      for (const factor of factorsToCompute) {
        let factorResult = diagnostic.value.results.find(
          (result) => result.level_details.factor === factor.id
        );

        if (factorResult) {
          factorLevelsResult.value.push(factorResult.level_details.level + 1);
        } else {
          factorLevelsResult.value.push(1);
        }
      }
      factorLevels.value = factorLevelsResult.value;
    };

    const updateGraphs = (groupId: number) => {
      selectedGroup.value = selectedGroup.value === groupId ? null : groupId;
      setFactorLevels(selectedGroup.value);
    };

    const setGlobalScore = () => {
      let finalResult = ref<number>(0);
      for (const result of diagnostic.value.results) {
        finalResult.value = finalResult.value + result.level_details.level;
      }
      globalScore.value = Math.round(
        (finalResult.value / (factors.value.length * 5)) * 100
      );
    };
    const computeScoreGroup = (groupId) => {
      let score = 0;
      for (const f of factors.value.filter((e) => {
        return e.group === groupId;
      })) {
        let factorResult = diagnostic.value.results.find(
          (result) => result.level_details.factor === f.id
        );
        if (factorResult) {
          score += factorResult.level_details.level;
        }
      }
      return Math.round(
        (100 * score) /
          (5 *
            factors.value.filter((e) => {
              return e.group === groupId;
            }).length)
      );
    };
    const displayFactorDetail = (event, chartContext, config) => {
      router.push({
        name: "edit-diagnostic",
        params: { diagnosticId: diagnostic.value.id },
        hash:
          "#" +
          factors.value
            .filter((e) => {
              if (selectedGroup.value) {
                return e.group === selectedGroup.value;
              } else {
                return true;
              }
            })
            [config.dataPointIndex].id.toString(),
      });
    };

    const chartOptionsBar = computed(() => {
      return {
        chart: {
          type: "bar",
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        legend: {
          show: false,
        },
        tooltip: {
          enabled: true,
          fixed: {
            enabled: true,
          },
          x: {
            formatter: (label) => {
              let modifiedLabel = factors.value.find(
                (factor) => factor.number === label
              ).title;
              return label + " : " + modifiedLabel;
            },
          },
          y: {
            formatter: (value) => {
              return "Niveau " + (value - 1).toString();
            },
            title: {
              formatter: () => "",
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: (value) => {
            return value - 1;
          },
        },
        plotOptions: {
          bar: {
            distributed: true,
            horizontal: true,
          },
        },
        colors: [({ value }) => correctResultColor(((value - 1) / 6) * 100)],
        xaxis: {
          categories: factors.value
            .filter((e) => {
              if (selectedGroup.value !== null) {
                return e.group === selectedGroup.value;
              }
              return true;
            })
            .map((factor) => factor.number),
          position: "top",
          tickAmount: 6,
          min: 0,
          max: 6,
          labels: {
            show: false,
          },
        },
        yaxis: {
          show: true,
          labels: {
            style: {
              colors: getCSSVariableValue("--el-color-black"),
              fontSize: [getCSSVariableValue("--el-font-size-small")],
              fontWeight: 800,
            },
          },
        },
      };
    });

    const chartOptionsRadar = computed(() => {
      return {
        chart: {
          type: "radar",
        },
        tooltip: {
          enabled: true,
          fixed: {
            enabled: true,
          },
          x: {
            formatter: (label) => {
              let modifiedLabel = factors.value.find(
                (factor) => factor.number === label
              ).title;
              return label + " : " + modifiedLabel;
            },
          },
          y: {
            formatter: (value) => {
              return "Niveau " + (value - 1).toString();
            },
            title: {
              formatter: () => "",
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: (value) => {
            return value - 1;
          },
        },
        plotOptions: {
          radar: {
            distributed: true,
            polygons: {
              strokeColors: "#e9e9e9",
              fill: {
                colors: ["#f8f8f8", "#fff"],
              },
            },
          },
        },
        colors: [getCSSVariableValue("--bs-primary")],
        xaxis: {
          categories: factors.value
            .filter((e) => {
              if (selectedGroup.value !== null) {
                return e.group === selectedGroup.value;
              }
              return true;
            })
            .map((factor) => factor.number),
          labels: {
            style: {
              colors: Array(factorLevels.value.length).fill(
                getCSSVariableValue("--el-color-black")
              ),
              fontSize: [getCSSVariableValue("--el-font-size-small")],
              fontWeight: 800,
            },
          },
        },
        yaxis: {
          show: false,
          tickAmount: 6,
          min: 0,
          max: 6,
        },
      };
    });

    const setType = (type) => {
      displayChart.value = false;
      chartType.value = type;
      setTimeout(() => {
        displayChart.value = true;
      }, 10);
    };
    const openSwal = () => {
      Swal.fire({
        title: "Services ecosytémiques !",
        html:
          "<p>Ce sont des <b> indicateurs représentant la contribution de vos pratiques à différents services " +
          "écosystémiques </b>, c’est-à-dire aux services rendus par les écosystèmes : services d’approvisionnement" +
          " (fourniture de bien et service, ressources génétiques…), services de régulation (production d’oxygène," +
          " régulation du climat et de l’air, contrôle des ravageurs…), services socio-culturels (paysage, loisir," +
          " tourisme …) et les services de soutien et support (cycle de l’eau, formation des sols…) </p> " +
          "<p>Ces indicateurs estiment le potentiel de contribution de vos pratiques à ces services. </p>" +
          "<p><b>Ils sont calculés sur la base des résultats de votre diagnostic</b>. Ces contributions augmentent avec" +
          " l’amélioration de vos pratiques en matière de biodiversité et plus globalement d’environnement.</p>" +
          "<ul>" +
          "<li>Si vous êtes entre 0 et 33%: vous avez un impact FAIBLE</li> <li>Si vous êtes entre 33% et 66%: vous" +
          " avez un impact MOYEN</li><li>Si vous être au-dessus de 66%: vous avez un impact FORT »</li></ul>",
        icon: "question",
        buttonsStyling: false,
        confirmButtonText: "Fermer",
        showCloseButton: true,
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      });
    };

    onMounted(() => {
      store.dispatch(Actions.FETCH_FACTOR_GROUPS);
      store
        .dispatch(Actions.FETCH_EXPLOITATIONS)
        .then(() => {
          store.dispatch(Actions.FETCH_QUIZ).then(() => {
            store.dispatch(Actions.FETCH_DIAGNOSTICS).then(() => {
              store
                .dispatch(Actions.FETCH_ECOSYSTEMIC_SERVICES_CATEGORIES)
                .then(() => {
                  if (
                    store.getters.getDiagnostics.some(
                      (diagnostic) => diagnostic.id === diagnosticId.value
                    )
                  ) {
                    diagnostic.value = store.getters.getDiagnostics.find(
                      (diagnostic) => diagnostic.id === diagnosticId.value
                    );
                    if (diagnostic.value.finished) {
                      setFactorLevels();
                      setGlobalScore();
                      setCurrentPageTitle("Résultats");
                    } else {
                      router.push({
                        name: "edit-diagnostic",
                        params: { diagnosticId: diagnostic.value.id },
                      });
                    }
                  } else {
                    router.push({
                      name: "404",
                    });
                  }
                });
            });
          });
        })
        .catch(() => {
          router.push({
            name: "edit-diagnostic",
            params: { diagnosticId: diagnostic.value.id },
          });
          notify({
            text: "Diagnostic non trouvé",
            color: "error",
          });
        });
    });

    const computeCategoryScore = (category) => {
      let category_score = 0;
      let category_max_score = 0;

      category.services.forEach((s) => {
        const activeFactorLevels = s.factor_levels.filter((e) => e.active);
        const max_score = activeFactorLevels.length;
        let score = 0;
        diagnostic.value.results.forEach((r) => {
          const level = r.level_details.level;
          const factorId = r.level_details.factor;
          const factorIdFromQuiz = factors.value.find((f) => f.id === factorId);
          const factorLevelIds = factorIdFromQuiz?.levels.map((l) => l.id);

          score += activeFactorLevels.filter((a) => {
            return (
              factorLevelIds?.includes(a.factor_level.id) &&
              a.factor_level.level <= level
            );
          }).length;
        });

        category_score += score;
        category_max_score += max_score;
      });

      return category_score / category_max_score;
    };

    const chartOptions = {
      chart: {
        type: "radialBar",
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -160,
          endAngle: 160,
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: 4,
              fontSize: "14px",
            },
          },
        },
      },
      colors: [({ value }) => correctServiceColor(value)],
    };

    const correctServiceColor = (result) => {
      if (result <= 33.3) {
        return "danger";
      } else if (result <= 66.6) {
        return "warning";
      } else if (result <= 100) {
        return "primary";
      }
    };

    return {
      diagnostic,
      diagnosticName,
      diagnosticId,
      factorLevels,
      globalScore,
      chartType,
      chartOptionsBar,
      chartOptionsRadar,
      displayChart,
      displayFactorDetail,
      setType,
      ecosystemicServicesCategories,
      computeCategoryScore,
      chartOptions,
      correctServiceColor,
      factorsGroups,
      setFactorLevels,
      computeScoreGroup,
      updateGraphs,
      selectedGroup,
      openSwal,
      factors,
    };
  },
});
