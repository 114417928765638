
import { PropType } from "vue";
import { Factor } from "@/store/modules/DiagnosticsModule";

export default {
  name: "FactorLegend",
  props: {
    factors: { type: Object as PropType<Array<Factor>>, required: true },
  },
};
